import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Text, Title } from "../Core";

import { FaGithub, FaMediumM, FaLinkedinIn } from "react-icons/fa";

import Logo from "../Logo";
import { rgba } from "polished";

import BrandGuide from "../../assets/alkemi-brand-guidelines.pdf";

import telegram from "../../assets/image/png/telegram.png";
import discord from "../../assets/image/png/discord.png";
import twitter from "../../assets/image/png/twitter.png";
import quantstamp from "../../assets/image/png/quantstamp.png";
import substack from "../../assets/image/png/substack.png";
import coinmarket from "../../assets/image/png/coinmarket.png";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const FooterMenuArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  }
`;
const LogoArea = styled.div`
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 22px;
      transition: 0.4s;
      padding-right: 10px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;
const ContentCard = ({
  color = "primary",
  img,
  exLink,
  title,
  children,
  ...rest
}) => (
  <Box width={"100%"} bg="ash" p="20px" borderRadius={10} {...rest}>
    <a
      href={exLink}
      target="_blank"
      rel="noreferrer"
      className="stretched-link"
    >
      <div className="d-flex align-items-start">
        <Box
          size={55}
          minWidth={55}
          minHeight={55}
          borderRadius="50%"
          color={color}
          fontSize="28px"
          className="d-flex justify-content-center align-items-center"
          mr="20px"
          css={`
            background-color: ${({ theme, color }) =>
              rgba(theme.colors[color], 0.1)};
          `}
        >
          <img src={img} mxWidth={5} alt="" />
        </Box>
        <div>
          <Text
            color="light"
            fontSize={3}
            fontWeight={500}
            letterSpacing={-0.75}
          >
            {title}
          </Text>
          <Text
            variant="small"
            fontSize="16px"
            lineHeight={1.75}
            color="lighterShade"
          >
            {children}
          </Text>
        </div>
      </div>
    </a>
  </Box>
);

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 100px 0 60px;
            `}
          >
            <Container>
              <Row className="justify-content-center">
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <ContentCard
                    color="light"
                    img={telegram}
                    title="Telegram"
                    exLink="https://t.me/AlkemiNetwork"
                  >
                    Join the Community
                  </ContentCard>
                </Col>
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <ContentCard
                    color="light"
                    img={discord}
                    title="Discord"
                    exLink="https://discord.gg/N7bMz6n2vX"
                  >
                    Ask Questions
                  </ContentCard>
                </Col>
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <ContentCard
                    color="light"
                    img={twitter}
                    title="Twitter"
                    exLink="https://twitter.com/AlkemiNetwork"
                  >
                    Follow Us
                  </ContentCard>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <ContentCard
                    color="light"
                    img={coinmarket}
                    title="CoinMarketCap"
                    exLink="https://coinmarketcap.com/da/currencies/alkemi-network-dao-token/project-info/"
                  >
                    View ALK on CoinMarketCap
                  </ContentCard>
                </Col>
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <ContentCard
                    color="light"
                    img={substack}
                    title="Substack"
                    exLink="https://defiinsights.substack.com/?utm_source=substack&utm_medium=web&utm_campaign=substack_profile"
                  >
                    Subscribe to DeFi Insights
                  </ContentCard>
                </Col>
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <ContentCard
                    color="light"
                    img={quantstamp}
                    title="Audit Report"
                    exLink="https://github.com/AlkemiNetwork/alkemi-earn-security-audit/blob/main/Quantstamp%20Security%20Audit%20of%20Alkemi%20-%20Report.pdf"
                  >
                    Quanstamp Audit Report
                  </ContentCard>
                </Col>
              </Row>
            </Container>
          </Box>
          <FooterMenuArea dark={isDark ? 1 : 0}>
            <Container>
              <Box
                css={`
                  padding: 80px 0 60px;
                `}
              >
                <Row className="justify-content-center">
                  <Col lg="3" md="4">
                    <LogoArea dark={isDark ? 1 : 0}>
                      <Logo white={isDark} />
                      <p>Copyright &copy; 2021</p>
                      <Box pt={3}>
                        <ul className="social-icons">
                          <li>
                            <a
                              href="https://github.com/AlkemiNetwork"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i>
                                <FaGithub />
                              </i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://medium.com/alkemi"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i>
                                <FaMediumM />
                              </i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/alkeminetwork"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i>
                                <FaLinkedinIn />
                              </i>
                            </a>
                          </li>
                        </ul>
                      </Box>
                    </LogoArea>
                  </Col>
                  <Col lg="9" md="8" className="mt-5 mt-lg-0">
                    <Row>
                      <Col xs="6" lg="3">
                        <div className="mb-5 mb-lg-4">
                          <TitleStyled
                            variant="card"
                            color={isDark ? "light" : "dark"}
                          >
                            Company
                          </TitleStyled>
                          <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <a
                                href="https://angel.co/company/alkemi/jobs"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Careers
                              </a>
                            </li>
                            <li>
                              <Link to="/latest-news">Latest News</Link>
                            </li>
                            <li>
                              <Link to="/faqs">FAQs</Link>
                            </li>
                            <li>
                              <Link to="/roadmap">Roadmap</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact Us</Link>
                            </li>
                          </UlStyled>
                        </div>
                      </Col>
                      <Col xs="6" lg="3">
                        <div className="mb-5 mb-lg-4">
                          <TitleStyled
                            variant="card"
                            color={isDark ? "light" : "dark"}
                          >
                            Resources
                          </TitleStyled>
                          <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                            <li>
                              <a
                                href="https://docs.alkemi.network/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Documentation
                              </a>
                            </li>
                            <li>
                              <Link to="/brand-assets">Brand Assets</Link>
                            </li>
                            <li>
                              <a
                                href={BrandGuide}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Brand Guidelines
                              </a>
                            </li>
                          </UlStyled>
                        </div>
                      </Col>
                      <Col xs="6" lg="3">
                        <div className="mb-lg-4">
                          <TitleStyled
                            variant="card"
                            color={isDark ? "light" : "dark"}
                          >
                            Alkemi Earn
                          </TitleStyled>
                          <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                            <li>
                              <a
                                href="https://earn.alkemi.network/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Go to Earn App
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://docs.alkemi.network/user-guide/using-the-earn-platform"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Earn User Guide
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://kyc.alkemi.network/#/auth/signup"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Apply for KYC
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://github.com/AlkemiNetwork/alkemi-earn-security-audit/blob/main/Quantstamp%20Security%20Audit%20of%20Alkemi%20-%20Report.pdf"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Audit Report
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://snapshot.org/#/alkemidao.eth"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Network Governance
                              </a>
                            </li>
                          </UlStyled>
                        </div>
                      </Col>
                      <Col xs="6" lg="3">
                        <div className="mb-lg-4">
                          <TitleStyled
                            variant="card"
                            color={isDark ? "light" : "dark"}
                          >
                            Find Us On
                          </TitleStyled>
                          <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                            <li>
                              <a
                                href="https://coinmarketcap.com/da/currencies/alkemi-network-dao-token/project-info/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                CoinMarketCap
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.coingecko.com/en/coins/alkemi-network-dao-token"
                                target="_blank"
                                rel="noreferrer"
                              >
                                CoinGecko
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://defillama.com/protocol/alkemi"
                                target="_blank"
                                rel="noreferrer"
                              >
                                DeFiLlama
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://dune.xyz/elliott/Alkemi-Earn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Dune Analytics
                              </a>
                            </li>
                          </UlStyled>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Box>
            </Container>
          </FooterMenuArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
