import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";

const SectionTitle = styled.h2`
  font-weight: 500;
  letter-spacing: -0.5px;
  font-size: 34px;
  line-height: 54px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 38px;
    line-height: 50px;
  }

  @media ${device.lg} {
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 15px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const IntroTitle = styled.h2`
  font-weight: 500;
  letter-spacing: -0.5px;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 30px;
    line-height: 15px;
  }

  @media ${device.lg} {
    font-size: 34px;
    line-height: 48px;
    margin-bottom: 15px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const HeroTitle = styled.h1`
  letter-spacing: 1px;
  font-size: 54px;
  line-height: 62px;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 70px;
    line-height: 76px;
  }

  @media ${device.lg} {
    font-size: 91px;
    line-height: 98px;
  }

  @media ${device.xl} {
    font-size: 91px;
    line-height: 98px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const PageTitle = styled(SectionTitle)`
  letter-spacing: 1px;
  font-size: 53px;
  line-height: 58px;
  margin-bottom: 20px;

  @media ${device.sm} {
    font-size: 64px;
    line-height: 68px;
  }

  @media ${device.lg} {
    font-size: 82px;
    line-height: 92px;
  }

  @media ${device.xl} {
    font-size: 82px;
    line-height: 92px;
  }
`;

const HeroSubtitle = styled.h2`
  font-size: 20px;
  line-height: 30px;
  padding-top: 14px;
  font-weight: 400;
  letter-spacing: -0.66px;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0px !important;
  }
`;

const PageSubtitle = styled.h3`
  font-size: 20px;
  line-height: 30px;
  padding-top: 14px;
  font-weight: 400;
  letter-spacing: -0.66px;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0px !important;
  }
`;

const CardTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Title = ({ variant, ...rest }) => {
  let TitleStyled = SectionTitle;

  switch (variant) {
    case "card":
      TitleStyled = CardTitle;
      break;
    case "hero":
      TitleStyled = HeroTitle;
      break;
    case "heroSubtitle":
      TitleStyled = HeroSubtitle;
      break;
    case "pagetitle":
      TitleStyled = PageTitle;
      break;
    case "pageSubtitle":
      TitleStyled = PageSubtitle;
      break;
    case "intro":
      TitleStyled = IntroTitle;
      break;
    default:
      TitleStyled = SectionTitle;
  }

  return <TitleStyled color="heading" {...rest} />;
};

export default Title;
