export const menuItems = [
  {
    name: "about",
    label: "About",
  },
  {
    name: "faqs",
    label: "FAQs",
  },
  {
    name: "roadmap",
    label: "Roadmap",
  },
  {
    name: "latest-news",
    label: "Latest News",
  },
  {
    name: "contact",
    label: "Contact",
  },
];
