import React from 'react'
import { Link } from 'gatsby'

import imgLogo from '../../assets/image/svg/alkemi-logo.svg'
import imgLogoWhite from '../../assets/image/svg/alkemi-logo-white.svg'

const Logo = ({ white, height, className = '', ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img src={imgLogoWhite} width="170px" alt="" />
      ) : (
        <img src={imgLogo} width="170px" alt="" />
      )}
    </Link>
  )
}

export default Logo
